import {EventDispatcher} from "../libs/fanfanlo/events/EventDispatcher";
import {DataEvent} from "../libs/fanfanlo/events/DataEvent";

export class MainModelBase extends EventDispatcher{
    static instance;
    static events = {
        addWaitingMember:"addWaitingMember",
        i18nChanged :"i18nChanged"
    }
    constructor() {
        super();
        mainModelBase = this;
        this.f7IsReady = false;
        MainModelBase.instance = this;
        this.waitingMembers = [];
    }
    addWaitingMember(func){
        this.dispatchEvent(new DataEvent(MainModelBase.events.addWaitingMember, func));
    }

}
export var mainModelBase;