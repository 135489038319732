
import {isWeiXin} from "../libs/fanfanlo/utils/utils";
import {getDevice} from "framework7";


const device = getDevice();
const isWx = isWeiXin()
const isAndroid = device.android;
const isIos = device.ios
const isAndroidInWx = isAndroid && isWx;
const isAndroidOutWx = isAndroid && !isWx;
const isIosInWx = isIos && isWx;
const isIosOutWx = isIos && !isWx;
const isPc = device.os != "ios" && device.os != "android";


const dev = "development";
const prod = "production";
let isOnlineEnv = false;
let gateway = "";
let htmlOrigin = window.location.origin + "/";
export let titlePrefix = "";
gateway = `//${window.location.hostname}${window.location.port ? ":19010":""}/v1/`
let isDev = process.env.NODE_ENV === dev;
let isProd = window.location.port.length > 0;

let isIosUrl = window.location.href.indexOf("?ios") > -1;
let isAndroidApp = window.location.href.indexOf("?app") > -1;
console.log('isProd, platform, prod', isProd, prod, isOnlineEnv, gateway);
export {isDev, isProd, gateway, isOnlineEnv,
    isIos, isIosUrl, isAndroid, isAndroidApp,
    isAndroidInWx, isAndroidOutWx, isPc, isIosInWx, isIosOutWx, isWx, htmlOrigin
}
